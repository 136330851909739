import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert,Button } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/gold3.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import getData from '../params/getdata'
import moment from 'moment'

let abu = "#909090"
let cmain = '#76684D'
let black = 'rgb(38,38,38)'

let id = 'vella-andi'
let inisial_co = 'Andi'
let inisial_ce = 'Vella'
let lengkap_co = 'Andi Firdaus'
let lengkap_ce = 'Vella Ahalla Hulaifah Somantri '
let bapak_co = 'Bpk. Abdul Syukur'
let ibu_co = 'Ibu Desya Usmayanti'
let bapak_ce = "Bpk. Deden Iwan Somantri (Alm.)"
let ibu_ce = "Ibu Ria Andayani Somantri"
let ig_co = "andifirdaus_"
let ig_ce = "vellaahalla"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "02/28/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/UVNfUx5zNBB8nfhj8"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MzVmNHVxcTdhcXQzMm40anNhaHNkODgzaTQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com  '
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.1359326960223!2d112.69942436477601!3d-7.45020989462529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7e1f1210b3623%3A0xbbcc0d78464edfaf!2sPondok%20mutiara%20MEA-38!5e0!3m2!1sid!2sid!4v1610032582106!5m2!1sid!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
gmaps1 = gmaps1.split('"')[1]


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            comment:[]
        }
    }

    async componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        let comment = await getData(`kepada:"${id}"`)
            

            this.setState({ comment: comment.reverse() })
            console.log(comment)

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, comment, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        let slide = [
            "IMG_2111.jpg",
            "IMG_2120.jpg",
            "IMG_2125.jpg",
            "IMG_2130.jpg",
            "IMG_2133.jpg",
            "IMG_2139 (1).jpg",
            "IMG_2222.jpg",
            "_MG_8275.jpg",
            "_MG_8330.jpg",
            "_MG_8389.jpg",
            "_MG_8391.jpg",
            "_MG_8477.jpg",
        ]
        slide.map(v => {
            slider.push(pw(id, v))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet"></link>
                    
<link href="https://fonts.googleapis.com/css2?family=Allura&display=swap" rel="stylesheet"></link>
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `none`,
                    backgroundColor: 'white',
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }} className='position-relative'>
                            {/* <div className="position-fixed" style={{width:'100vw',height:'100vh',left:0,top:0}}> */}
                                <Col xs="5" md="3" style={{position:'fixed',left:0,bottom:0}} >
                                    <img src={pw(id,"bunga1.svg")} className="w-100 img-fluid"/>
                                </Col>
                                <Col xs="6" md="3" style={{position:'fixed',right:0,top:0}} >
                                    <img src={pw(id,"bunga2.svg")} className="w-100 img-fluid"/>
                                </Col>
                            {/* </div> */}
                      
                                <div id="rizki-bima" style={{position:'relative'}}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center px-3" style={{ color: cmain }}>
                                            “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.” <br /><br />(Ar-Rum: 21)


                                        </p>
                                    </Item>

                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("irli-rizki", "daun.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br />
                                        </p>
                                        <h1>
                                            {inisial_ce} & {inisial_co}
                                        </h1>

                                    </Item>
                                    
                                </Container>
                                
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className=' mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain,fontFamily:'Allura, cursive' }} >
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                {/* <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item> */}
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{
                                                            fontSize: '24px'
                                                          , color: cmain,
                                                          fontFamily: 'Poppins, sans-serif'
                                                        }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center'
                                                        style={{ fontSize: '18px', color: cmain,
                                                        fontFamily:'Poppins, sans-serif' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-black.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>

                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' 
                                        data-aos="fade-right" data-aos-duration="1000">
                                            <div className=' mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px',  color: cmain,fontFamily: 'Allura, cursive' }} >
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                {/* <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item> */}
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center " style={{
                                                        fontSize: '24px',
                                                        fontFamily:'Poppins, sans-serif',
                                                       color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{
                                                        fontSize: '18px', color: cmain,
                                                        fontFamily:'Poppins, sans-serif'
                                                    }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-black.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>

                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: cmain }} >
                                    <Item>
                                        <p className="fs16" >
                                            Yang Insyaa Allah akan dilaksanakan pada:
                                        </p>
                                    </Item>

                                    <Item>
                                        <p className="fs20 col-sm-4 px-2" style={{ color: cmain }}>
                                            <b style={{fontSize:'24px'}}>Akad Nikah </b><br />
                                            <span className="cblack">
                                                <b>
                                                    Minggu, 28 Februari 2021
                                                </b><br />
                                                08.00 WIB - Selesai

                                            </span>
                                            
                                        </p>
                                        <p className="fs20 col-sm-4 px-2" style={{ color: cmain }}>
                                            <b style={{fontSize:'24px'}}>Resepsi</b><br />
                                            <span className="cblack">
                                                <b>
                                                Minggu, 28 Februari 2021
                                                </b><br />
                                            18.00 WIB- Selesai
                                                 </span>
                                        </p>

                                    </Item>
                                    {/* <Container className="text-center py-5 dinny" id="dinny">

                                        <>
                                            <Item>
                                                <h1 style={{
                                                    fontFamily:'Marck Script, cursive',
                                                    fontSize: '36px',
                                                    color: cmain
                                                }} data-aos={`fade-right`} data-aos-duration="2000">
                                                    Susunan Acara
        </h1>
                                            </Item>

                                            {
                                                [
                                                    // { waktu: "	09.00 -10.00	", acara: "	Akad Nikah", icon: "	https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" },
                                                    { waktu: "	9.30-10.30 ", acara: "	prosesi pernikahan purna praja dharma astabratha", icon: "	https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg	" },
                                                    { waktu: "	10.30-11.00", acara: "sesi foto", icon: "	https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg	" },

                                                    { waktu: "	11.00-12.00", acara: "acara adat duek bisan", icon: pw("asset", "aceh1.png") },
                                                    { waktu: "12.00-Selesai", acara: "memberi selamat kepada pengantin", icon: pw("asset", "salaman.png") },
                                                   

                                                ].map((v, i) => {
                                                    i++
                                                    return (
                                                        <Row className="py-2" data-aos={`fade-left`} data-aos-duration="2000">
                                                            <Col className={i % 2 ? "order-md-1" : "order-md-2"}>
                                                                <Row >
                                                                    <Col md={3} xs={3} className={`order-1 ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                                        <img src={v.icon} className="img-fluid w-100 px-1 px-md-2 my-auto" />
                                                                    </Col>
                                                                    <Col xs={8} className={`order-1 text-left ${i % 2 ? "order-md-1 text-md-right" : "order-md-2 text-md-left"}`}>
                                                                        <p className=" fs16" style={{ color: cmain }}>
                                                                            <b>
                                                                                {v.waktu}
                                                                            </b><br />
                                                                            {v.acara}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className={`d-none d-md-block ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }

                                        </>
                                    </Container> */}

                                    <Item>
                                        <p className="fs16 pt-3" style={{ color: cmain }}>
                                        <b>Pondok Mutiara MEA-38 Sidoarjo</b> 
                                        {/* <br/>
Jl. Lio Garut No.1, Parigi Baru, Kec. Pd. Aren, Kota Tangerang Selatan, Banten 15228 */}
                                        </p>
                                    </Item>

                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    {/* <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item> */}

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="text-center dinny">
                                    <Item>
                                        <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                                            <h1 className="fs30"
                                                style={{

                                                   
                                                    color: cmain
                                                }}>
                                                Protokol Kesehatan
                          </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                        Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Gunakan Masker
                        </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Jaga Jarak
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'salaman.png')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Cukup Bersalaman tanpa Bersentuhan
                      </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className=' col-10' style={{ backgroundColor: 'white' }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14' style={{ fontFamily:'Poppins, sans-serif' ,color:cmain}}>
                                                        50000 years before the sky was introduced to the sea, Allah write down your name next to me. And Finally we find each other.
                             
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("irli-rizki", "daun.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100 p-2' />
                                        </Col>
                                    </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                                                     </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                            <div id="formradio">
                                                <div class="custom_radio row justify-content-center">
                                                    <div onClick={() => {
                                                        this.setState({ hadir: true })
                                                    }
                                                    }>
                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                        <label for="featured-1">Hadir</label>
                                                    </div>
                                                    <div onClick={() => {
                                                        this.setState({ hadir: false })
                                                    }
                                                    } className="pl-5">
                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                        <label for="featured-2"
    
                                                        >Tidak Hadir</label>
                                                    </div>
                                                   
    
                                                </div>
                                            </div>
                                        </Item>
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container>
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                            {comment.map((v, i) => {
                                                console.log(v)
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-left my-auto" 
                                                            style={{ fontSize: '18px',color:'white' }}>
                                                                <b>{decodeURI(v.dari)}</b><br />
                                                                {decodeURI(v.pesan)}<br />
                                                                <span  style={{ fontSize: '14px'}}>
                                                                {moment(v.createdAt).startOf('hour').fromNow()}
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            })
                                            }
                                        </Row>
                                    </Container>
                                    
                                
                                </div>
                                <Foot ig={pw("asset", "logoig-black.svg")} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

